<template>
  <div ref="main">
    <div class="h-100"></div>
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-4 col-12">
          <Lottie :path="'animate/loading-168.json'" :title="''" :info="''" :show="showLottie" ref="lottieFunc" />
      </div>
    </div>
  
    <Common ref="commonFunc"/>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Starter page
 */
export default {
  components: { 
    Lottie,
    Common
  },
  page() {
    return {
      title: "Logout",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:true,
      title: '',
      items: [
        {
          text: "",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
    };
  },
  mounted(){
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()
    this.getData()
  },
  created(){
    
  },
  methods:{
    getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone", this.accessPhone);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.$refs.commonFunc.clear()
              this.$router.push({
                  path: "/login",
              });
            }else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clear()
                this.$router.push({
                    path: "/login",
                });
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    }
  }
};
</script>